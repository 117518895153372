@import 'styles/index';

body {
  margin: 0;
  padding: 0;
  background-color: $black;
  overflow: hidden;
  font-size: 16px;
  font-family: $font-family1;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;

  @include breakpoint($xxl) {
    font-size: 15px;
  }

  @include breakpoint($xl) {
    font-size: 14px;
  }

  @include breakpoint($lg) {
    font-size: 13px;
  }

  @include breakpoint($md) {
    font-size: 12px;
  }

  @include breakpoint($sm) {
    font-size: 11px;
  }
  @include breakpoint($xs) {
    font-size: 10px;
  }

  @include breakpoint($xxs) {
    font-size: 10px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #3c3c3c;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.margin-auto {
  margin: auto;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.flex-column {
  flex-direction: column;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.items-center {
  align-items: center;
}

.absolute {
  position: absolute;
}

.hide {
  visibility: hidden;
}

.outlined-btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.btn-text {
  font-size: 24px;
  font-family: $font-family1;
  font-weight: 600;
}


.text-gray {
  color: #707070 !important;
}

.icon-btn {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #707070;
}

.p-2 {
  padding: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.align-center {
  align-items: center;
}

.relative {
  position: relative;
}

.grid {
  display: grid;
}

.color-cyan {
  color: $cyan;
}

.color-grey {
  color: $grey;
}

.font-bold {
  font-weight: bold;
}

.color-white {
  color: white;
}

.text-gemunu {
  font-family: 'GemunuLibre';
}

.text-helvetica {
  font-family: Helvetica;
}


